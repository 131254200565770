<template>
  <transition name="fade-fast">
    <div v-show="mostrar" class="app-popup-container">
      <div class="app-popup-mask" @click="cerrar()"></div>
      <div class="novedad">
        <label class="novedad-title">Crear nueva novedad</label>
        <div class="novedad-body">
          <div>
            <div class="novedad-row">
              <label style="visibility: hidden">Titulo del novedad</label>
              <div
                class="novedad-imagen"
                @click="cambiarImagen()"
                :class="{ 'error-input': errorImagen }"
              >
                <img src="../assets/img/icons/imagen.png" />
                <label v-show="imagen == null">Agregar imágen</label>
                <label v-show="imagen != null">{{ nombreImagen }}</label>
              </div>
              <input
                class="app-hide"
                type="file"
                ref="imagenUpload"
                @change="changeImagen()"
                accept="image/png, image/jpeg"
              />
            </div>
            <div class="novedad-row">
              <label>Titulo del novedad
                <span :class="tituloLongitudRestante > 0 ? 'novedad-longitud' : 'novedad-longitud-error'">{{tituloLongitudRestante}} Caracteres</span>
              </label>
              <input
                type="text"
                placeholder="Escribe título"
                v-model="titulo"
                :class="{ 'error-input': errorTitulo }"
              />
            </div>
            <div class="novedad-row">
              <label>Descripción
                <span :class="descripcionLongitudRestante > 0 ? 'novedad-longitud' : 'novedad-longitud-error'">{{descripcionLongitudRestante}} Caracteres</span>
              </label>
              <textarea
                type="text"
                placeholder="Escribe una breve descripción"
                v-model="descripcion"
                :class="{ 'error-input': errorDescripcion }"
              ></textarea>
            </div>
            <div class="novedad-row">
              <input
                class="novedad-link"
                type="text"
                placeholder="Inserta Link de enlace"
                v-model="link"
                :class="{ 'error-input': errorLink }"
              />
            </div>
          </div>
          <div>
            <img
              class="novedad-cerrar"
              src="../assets/img/icons/close.png"
              @click="cerrar()"
            />
            <div class="novedad-row">
              <label>Visibilidad</label>
              <Dropdown
                :options="roles"
                :placeholder="'Todos'"
                :paraArriba="false"
                :name="'detalle'"
                :conInput="true"
                v-on:updateOption="updateDropdownRoles"
              ></Dropdown>
            </div>
            <div class="novedad-fijar">
              <div class="novedad-fijar-img">
                <img src="../assets/img/botones/info.png" />
                <div class="novedad-fijar-info">
                  <label>Se mostrará arriba en la grilla de novedades</label>
                </div>
              </div>
              <label class="switch">
                Fijar publicación
                <input type="checkbox" v-model="fijar" />
                <span class="slider round"></span>
              </label>
            </div>
            <div class="novedad-row">
              <label>Vista previa</label>
              <div class="novedad-previa">
                <div class="novedad-previa-imagen-container">
                  <img
                    v-show="imagen != null"
                    class="novedad-previa-imagen-img"
                    ref="imagen"
                  />
                  <img
                    v-show="imagen == null"
                    class="novedad-previa-imagen-icon"
                    src="../assets/img/icons/imagen.png"
                  />
                </div>
                <label class="novedad-previa-titulo">{{
                  titulo != "" ? titulo : "Título"
                }}</label>
                <label class="novedad-previa-descripcion">{{
                  descripcion != "" ? descripcion : "Descripción"
                }}</label>
                <div class="novedad-leer-mas">
                  <label>Abrir novedad</label>
                  <img src="../assets/img/botones/leer_mas_blue.png" />
                </div>
              </div>
            </div>
            <div class="novedad-btn-container">
              <button class="novedad-btn" @click="guardar()">Listo</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";

export default {
  name: "novedadpopup",
  components: { Dropdown },
  data() {
    return {
      idElegido: null,
      mostrar: false,
      roles: [
        {
          id: "distribuidor_gerente",
          detalle: "Distribuidor - Dueño/Gerente/Director",
        },
        {
          id: "distribuidor_administrativo",
          detalle: "Distribuidor - Administrativo",
        },
        {
          id: "distribuidor_vendedor",
          detalle: "Distribuidor - Vendedor",
        },
        {
          id: "planes_comerciales",
          detalle: "Planes Comerciales",
        },
        {
          id: "basf",
          detalle: "BASF",
        },
        {
          id: "todos",
          detalle: "Todos",
        },
      ],
      rolElegido: null,
      titulo: "",
      tituloMax: 255,
      descripcion: "",
      descripcionMax: 255,
      imagen: null,
      nombreImagen: "",
      errorImagen: false,
      errorTitulo: false,
      errorDescripcion: false,
      errorLink: false,
      fijar: false,
      link: null
    };
  },
  created() {},
  mounted() {
    this.$eventHub.$on("novedad-abrir", () => (this.mostrar = true));
    this.$eventHub.$on("novedad-editar", (novedad) => this.editar(novedad));
  },
  computed: {
    descripcionLongitudRestante() {
      return this.descripcionMax - this.descripcion.length
    },
    tituloLongitudRestante() {
      return this.tituloMax - this.titulo.length
    }
  },
  methods: {
    cerrar() {
      this.mostrar = false;
      this.imagen = null;
      this.nombreImagen = "";
      this.titulo = "";
      this.descripcion = "";
      this.link = "";
      this.errorImagen = false;
      this.errorTitulo = false;
      this.errorDescripcion = false;
      this.errorLink = false;
      this.idElegido = null;
      this.rolElegido = null;
      this.$refs.imagen.src = "";
      this.fijar = false;
    },
    editar(novedad) {
      this.imagen = novedad.imagen;
      this.nombreImagen = novedad.imagenNombre;
      this.titulo = novedad.titulo;
      this.descripcion = novedad.descripcion;
      this.link = novedad.link;
      this.idElegido = novedad.id;
      this.fijar = novedad.fijar;
      this.mostrar = true;
    },
    updateDropdownRoles(payload) {
      if (payload.id != "todos") {
        this.rolElegido = payload.id;
      } else {
        this.rolElegido = null;
      }
    },
    cambiarImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function (e) {
        that.reduceImageSize();
        that.$nextTick(() => {
          that.$refs.imagen.src = e.target.result;
        });
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      let file = this.$refs.imagenUpload.files[0];
      this.nombreImagen = file.name;
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function (readerEvent) {
          let image = new Image();
          image.onload = function () {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 500, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.imagen = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    guardar() {
      let continuar = true;

      if (this.imagen == null) {
        this.errorImagen = true;
        continuar = false;
      } else {
        this.errorImagen = false;
      }

      if (this.titulo == "" || this.tituloLongitudRestante < 0) {
        this.errorTitulo = true;
        continuar = false;
      } else {
        this.errorTitulo = false;
      }

      if (this.descripcion == "" || this.descripcionLongitudRestante < 0) {
        this.errorDescripcion = true;
        continuar = false;
      } else {
        this.errorDescripcion = false;
      }

      if (this.link == "" || !this.link) {
        this.errorLink = true;
        continuar = false;
      } else {
        this.errorLink = false;
      }

      if (continuar) {
        let json = {
          id: this.idElegido,
          imagen: this.imagen,
          imagenNombre: this.nombreImagen,
          titulo: this.titulo,
          descripcion: this.descripcion,
          link: this.link,
          rol: this.rolElegido,
          fijar: this.fijar,
        };
        const that = this;
        this.$axios
          .put(this.$localurl + "/api/novedad", json)
          .then(function () {
            that.$eventHub.$emit("novedad-nuevo");
          })
          .catch(function (response) {
            console.log(response);
          });
        this.cerrar();
      }
    },
  },
};
</script>

<style scoped src="../assets/css/components/novedadpopup.css"></style>