<template>
  <div class="loading_inline">
    <span class="icon material-symbols-outlined">
      progress_activity
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped src="@/assets/css/components/loadingInline.css"></style>