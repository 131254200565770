export default {
  data() {
    return {
      avatarColors: [
        {
          text: "#00749A",
          background: "#E6F6FB"
        },
        {
          text: "#014A9F",
          background: "#E6EDF5"
        },
        {
          text: "#007C30",
          background: "#E6F2EA"
        },
        {
          text: "#4EAE01",
          background: "#EDF7E6"
        },
        {
          text: "#FF9001",
          background: "#FFF4E6"
        },
        {
          text: "#D80014",
          background: "#FBE6E8"
        },
        {
          text: "#000000",
          background: "#E6E6E6"
        },
      ]
    }
  },
  methods: {
    getAvatarColor(index) {
      return this.avatarColors[index % this.avatarColors.length];
    },
  }
};