function formatDate(input) {
  const day = input.substring(8, 10);
  const month = input.substring(5, 7);
  const year = input.substring(0, 4);
  const date = day + "-" + month + "-" + year;
  return date;
}
function reformatDate(input) {
  const day = input.substring(0, 2);
  const month = input.substring(3, 5);
  const year = input.substring(6, 10);
  const date = year + "-" + month + "-" + day;
  return date;
}
function ordenarJson(json, prop, asc) {
  const result = json.sort(function(a, b) {
    const aProp = a[prop] != "-" ? a[prop] : null;
    const bProp = b[prop] != "-" ? b[prop] : null;
    if (asc) {
      return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
    }
    return bProp > aProp ? 1 : bProp < aProp ? -1 : 0;
  });
  return result;
}
function ordenarJsonAlfanumeric(json, prop, asc) {
  const result = json.sort(function(a, b) {
    const aProp = a[prop] != "-" ? a[prop] : null;
    const bProp = b[prop] != "-" ? b[prop] : null;
    if (asc) {
      return aProp.localeCompare(bProp, "en", { numeric: true });
    }
    return bProp.localeCompare(aProp, "en", { numeric: true });
  });
  return result;
}
function ordenarJson2do(json, prop1, prop2, asc) {
  const result = json.sort(function(a, b) {
    const aProp =
      a[prop1] != null && a[prop1][prop2] != null ? a[prop1][prop2] : "-";
    const bProp =
      b[prop1] != null && b[prop1][prop2] != null ? b[prop1][prop2] : "-";
    if (asc) {
      return aProp.localeCompare(bProp, "en", { numeric: true });
    }
    return bProp.localeCompare(aProp, "en", { numeric: true });
  });
  return result;
}
function formatStringToImporte(importe) {
  return importe
    .toString()
    .replace(/\./g, "")
    .replace(/,/g, ".");
}
function formatImporteToString(importe) {
  return (Math.round(importe * 100) / 100)
    .toFixed(2)
    .toString()
    .replace(/\./g, ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
function formatImporteToStringSinFixed(importe) {
  return (Math.round(importe * 100) / 100)
    .toString()
    .replace(/\./g, ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
function formatNaturalNumber($event) {
  const keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if (keyCode < 48 || keyCode > 57) {
    // 46 is dot
    $event.preventDefault();
  }
}
function formatDoubleNumber($event) {
  const keyCode = $event.keyCode ? $event.keyCode : $event.which;
  if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
    // 46 is dot
    $event.preventDefault();
  }
}
function formatImporteInput(el) {
  el.target.value = el.target.value
    .toString()
    .replace(/[a-z]/gi, "")
    .replace(/\./g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
function validateEmail(email) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

export {
  formatDate,
  reformatDate,
  ordenarJson,
  ordenarJson2do,
  ordenarJsonAlfanumeric,
  formatImporteToString,
  formatImporteToStringSinFixed,
  formatStringToImporte,
  formatNaturalNumber,
  formatDoubleNumber,
  formatImporteInput,
  validateEmail,
};
