<template>
  <div v-infocus="'showElement'" class="hidden hidden-down novedad-container">
    <button
      v-if="getRolUsuario()"
      class="novedad__action-button novedad__delete-button"
      @click.stop="openDeleteModal()"
    >
      <img src="../assets/img/icons/dark-delete.svg" alt="eliminar" />
    </button>
    <img
      class="academy-image"
      v-bind:src="creadorImage()"
      alt="course thumbnail"
    />
    <div class="novedad-info">
      <h2>{{ novedad.titulo }}</h2>
      <p>{{ novedad.descripcion }}</p>
      <a :href="novedad.link" target="_blank" rel="noopener">Abrir novedad
        <img src="../assets/img/botones/leer_mas_blue.png">
      </a>
    </div>
    <!-- Delete modal -->
    <div
      v-if="deleteModalIsOpen"
      class="delete-novedad-modal__background"
      @click="closeDeleteModal()"
    >
      <div class="delete-novedad-modal__container" @click.stop>
        <div class="delete-novedad-modal__header">
          <p>Eliminar</p>
          <img
            class="delete-novedad-modal__close-icon"
            src="../assets/img/landing/close.svg"
            alt="close"
            @click="closeDeleteModal()"
          />
        </div>
        <p>¿Estás seguro de eliminar la publicación ?</p>
        <div class="delete-novedad-modal__buttons">
          <button
            class="delete-novedad-modal__cancelar"
            @click="closeDeleteModal()"
          >
            Cancelar
          </button>
          <button
            class="delete-novedad-modal__eliminar"
            @click="deleteNovedad()"
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    novedad: Object
  },
  data() {
    return {
      deleteModalIsOpen: false
    };
  },
  methods: {
    creadorImage() {
      return !this.anuncio?.creador?.imagen
        ? this.$localurl + "/" + this.novedad.imagen
        : require("../assets/img/default.png");
    },
    openDeleteModal() {
      this.deleteModalIsOpen = true;
    },
    closeDeleteModal() {
      this.deleteModalIsOpen = false;
    },
    deleteNovedad() {
      const that = this;
      this.$axios
        .delete(this.$localurl + "/api/novedad/" + this.novedad.id)
        .then(function() {
          that.$eventHub.$emit("novedad-nuevo");
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    getRolUsuario() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance") ||
        this.$usuario.email == "ana.spioussas@basf.com" ||
        this.$usuario.email == "maria-ana.laguzzi@basf.com" ||
        this.$usuario.email == "gustavo.saldania@basf.com" ||
        this.$usuario.email == "jeronimo.carmody-fernandez@basf.com"
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped src="../assets/css/components/novedad.css"></style>
